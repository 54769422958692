import { useLocalStorage } from "usehooks-ts";

/**
 * Hook to manage the progress of an event.
 * @param key A unique key to store the progress in local storage.
 * @param initialState The initial state of the progress.
 */

export function useEventProgress(key: string) {
  // progress looks like ["2024-05-05", "2024-05-06"] in local storage
  const [progress, setProgress, removeProgress] = useLocalStorage<Array<string>>(`bc-event-progress-${key}`, []);

  function markDayComplete(date: string) {
    setProgress((prevProgress) => {
      const exists = prevProgress.findIndex((item) => item === date);
      if (exists === -1) {
        return [...prevProgress, date];
      }
      return prevProgress;
    });
  }

  function markDayIncomplete(date: string) {
    setProgress((prevProgress) => {
      const updatedProgress = prevProgress.filter((item) => item !== date);
      return updatedProgress;
    });
  }

  function resetProgress() {
    removeProgress();
  }

  return { progress, markDayComplete, markDayIncomplete, resetProgress };
}
